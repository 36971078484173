<template>
  <div class="project-new">
    <teleport v-if="mountedComponent" to="#header-nav">
      <CBreadcrumb>
        <CBreadcrumbItem>
          <router-link :to="{ name: 'ProjectsList' }">Projects</router-link>
        </CBreadcrumbItem>

        <CBreadcrumbItem active>
          <CSelectSearch
            v-model="selectedProject"
            class="project-new__select"
            :options="projectsListForBreadcrumb"
            :search="true"
            :loading="loadingProjects"
          />
        </CBreadcrumbItem>
      </CBreadcrumb>
    </teleport>

    <teleport v-if="mountedComponent" to="#projects-header">
      <TopNavigation :links="topNavLinks" />
    </teleport>

    <ProjectDetailsNew />
  </div>
</template>

<script>
import breadcrumbsMixin from '../breadcrumbsMixin'
import navLinkMixin from '../navLinkMixin'
import ProjectDetailsNew from '@/views/Projects/Project/Info/Details'
export default {
  name: 'Index',
  components: { ProjectDetailsNew },
  mixins: [breadcrumbsMixin, navLinkMixin],
  computed: {
    projectsListForBreadcrumb() {
      return [{ value: null, label: 'New Project' }, ...this.projectsList]
    },
  },
}
</script>

<style lang="scss" scoped>
.project-new {
  &__select {
    width: 162px;
  }
}
</style>
